import Util from "../util/util";
import Player from "../util/player";
import StorageManager from "../util/storage-manager";

const sounds = [
  {page: 'home', src: [`${import.meta.env.BASE_URL}audio/ambient.mp3`]},
]
export default class Sound {
  constructor(){
    this.buttons = Array.from(document.querySelectorAll('.sound-toggle'));
    this.generalButtons = this.buttons.filter((button) => !button.closest('#intro'));
    this.storage = new StorageManager();

    this.lastPlayed = this.storage.get('lastPlayed');
    let sound = sounds.find((sound) => sound.page === this.getPageName());
    if(sound) {
      this.src = sound.src[0];
    }else {
      this.src = sounds[0].src[0];
    }
    this.player = new Player();

    //this.mutedByUser = this.storage.get('mutedByUser') == true;
    this.mutedByUser = false;
    this.buttons.forEach((button) => {
      button.addEventListener('click', () => {
        if(button.classList.contains('playing')) {
          this.storage.set('mutedByUser', true);
          this.mutedByUser = true;
          window.canPlay = false;
          this.stopBackground();
        }else {
          this.storage.set('mutedByUser', false);
          this.mutedByUser = false;
          window.canPlay = true;
          this.playBackground();
        }
      });
    });
    if(this.mutedByUser) {
      window.canPlay = false;
      this.updateButtons(false);
      Util.waitForLoader(() => {
        console.log('waitForLoader', window.canPlay);
        if(window.canPlay) {
          this.init();
        }else {
          const checkPlay = () => {
            console.log('check play');
            document.removeEventListener('click', checkPlay);
            this.init();
          }
          document.addEventListener('click', checkPlay);
        }
      })
    }else {
      Util.canAutoplay((canAutoplay) => {
        window.canPlay = canAutoplay;
        this.updateButtons(canAutoplay);
        Util.waitForLoader(() => {
          console.log('waitForLoader', window.canPlay);
          if(window.canPlay) {
            this.init();
          }else {
            const checkPlay = () => {
              console.log('check play');
              document.removeEventListener('click', checkPlay);
              this.init();
            }
            document.addEventListener('click', checkPlay);
          }
        })
      })
    }
    this.initElements();
  }
  init() {
    if(window.pageName === 'home' || window.pageName === 'index') {
      if(this.mutedByUser) {
        window.canPlay = false;
        this.updateButtons(false);
      }else {
        Util.canAutoplay((canAutoplay) => {
          window.canPlay = canAutoplay;
          if(canAutoplay && !this.player.isPlaying){
            this.playBackground();
            console.log('play background');
          }
        })
      }
    }
    
  }
  initElements() {
    const hoverPlayer = new Player();

    const hoverElements = Array.from(document.querySelectorAll('[data-play-on-hover]'));
    hoverElements.forEach((element) => {
      const name = element.getAttribute('data-play-on-hover');
      const src = `${import.meta.env.BASE_URL}audio/${name}.mp3`;
      element.addEventListener('mouseenter', () => {
        Util.canAutoplay((canAutoplay) => {
          if(canAutoplay)
            hoverPlayer.play(src, () => {});
        });
      });
    });
    const clickElements = Array.from(document.querySelectorAll('[data-play-on-click]'));
    clickElements.forEach((element) => {
      const name = element.getAttribute('data-play-on-click');
      const src = `${import.meta.env.BASE_URL}audio/${name}.mp3`;
      element.addEventListener('click', () => {
        Util.canAutoplay((canAutoplay) => {
          if(canAutoplay)
            hoverPlayer.play(src, () => {});
        });
      });
    });
  }
  getPageName() {
    let name = 'home';
    return name;
  }
  updateButtons(playing) {
    this.buttons.forEach((button) => {
      button.classList.toggle('playing', playing);
      button.classList.remove('disabled');
    });
  }
  playBackground() {
    this.disabled = false;
    this.player.play(this.src);
    this.updateButtons(true);
  }
  resumeBackground() {
    if(this.disabled) {
      this.disabled = false;
      if(this.generalButtons.some((button) => button.classList.contains('playing'))) {
        this.playBackground();
      }else {
        this.generalButtons.forEach((button) => {
          button.classList.remove('disabled');
        });
      }
    }
  }
  pauseBackground() {
    this.player.fadeout();
    this.disabled = true;
    this.generalButtons.forEach((button) => {
      button.classList.add('disabled');
    });
  }
  stopBackground() {
    this.player.fadeout();
    this.updateButtons(false);
  }
}