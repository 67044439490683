import Util from '../util/util';
import Lenis from '@studio-freight/lenis'
import gsap from 'gsap';
import SmarText from '../components/smart-text.js';
import IconMenu from '../components/icon-menu';
import MenuPopup from '../components/menu-popup.js';
import ShareButton from '../components/share-button.js';
import ShareButtonLeader from '../components/share-button-leader.js';
import Sound from '../components/sound.js';


export default class Page {
  constructor(options) {
    this.options = options || {};
    this.pageName = this.options.name || 'home';
    window.pageName = this.pageName;
    this.init();

    Util.waitForLoader(this.pageReady);
  }
  init() {
    this.resize = this.resize.bind(this);
    this.paralax = this.paralax.bind(this);
    this.pageReady = this.pageReady.bind(this);

    this.isTouch = Util.isTouch();

    
    document.documentElement.setAttribute('data-browser', Util.getBrowser());
    document.documentElement.setAttribute('data-touch', this.isTouch);

    window.addEventListener('resize', () => {
      this.resize();
    });

    this.lenis = new Lenis({
    })
    this.lenis.scrollTo(0, {
      immediate: true,
      force: true
    });
    this.scrollVelocity = {c: 0, t: 0}

    this.lenis.on('scroll', (e) => {
      this.paralax(e)
    })

    const raf = (time) =>{
      this.lenis.raf(time)
      requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)

    this.paralax();
    this.setOS();
  }
  initIntro() {
    document.querySelectorAll('body > *:not(#loading)').forEach(($el) => {
      $el.classList.add('no-transition');
    });
    gsap.set(document.querySelector('#navigation'), {opacity: 0});
  }
  intro() {
    document.querySelectorAll('body > *:not(#loading)').forEach(($el) => {
      $el.classList.remove('no-transition');
    });
    gsap.to(document.querySelector('#navigation'), {opacity: 1, duration: 1, ease: 'power4.out'});
  }
  paralax(data) {
    this.scrollVelocity.t = data ? data.velocity : 0
    this.scrollVelocity.c += (this.scrollVelocity.t - this.scrollVelocity.c) * 0.1

    this.scrollItems = Array.from(document.querySelectorAll(".fade-in-y, .fade-in"));
    this.scrollItems.forEach((item) => {
      const offset = item.getAttribute('data-scroll-offset') ? Number(item.getAttribute('data-scroll-offset')) : 80;
      if (!item.classList.contains('show') && Util.isInViewportDom(item, offset)) {
        item.classList.add('show')
      }else if(item.classList.contains('show') && item.getBoundingClientRect().top > this.winH) {
        item.classList.add('no-transition');
        item.classList.remove('show');
        item.classList.remove('no-transition');
      }
    });
  }
  pageReady() {
    window.sound = new Sound();
    this.resize();
    this.initSmartText();
    this.initIntro();
    this.lenis.scrollTo(0, {
      immediate: true,
      force: true
    });
    setTimeout(() => {
      this.intro();
    }, 1000)
  }
  setOS() {
    let os = "osx";
    if (navigator.platform) {
      if (navigator.platform.indexOf("Win") > -1) {
        os = "windows";
      } else if (navigator.platform.indexOf("Mac") > -1) {
        os = "osx";
      } else if (navigator.platform.indexOf("Linux") > -1) {
        os = "linux";
      }
    } else {
      if (navigator.userAgent.indexOf("Windows") > -1) {
        os = "windows";
      } else if (navigator.userAgent.indexOf("Mac") > -1) {
        os = "osx";
      } else if (navigator.userAgent.indexOf("Linux") > -1) {
        os = "linux";
      }
    }
    document.documentElement.setAttribute("data-os", os);
    document.documentElement.setAttribute("data-page", this.pageName);
  }
  initSmartText() {
    const smartTexts = Array.from(document.querySelectorAll('.smart-text'));
    smartTexts.forEach((item) => {
      new SmarText({$el: item});
    });
  }
  resize() {
    this.isTouch = Util.isTouch();
    this.winW = window.innerWidth;
    this.winH = window.innerHeight;
  
    // Set the viewport size for css
    const vh = this.winH * 0.01;
    this.isMobile = window.innerWidth <= 1024;
  
    if (this.isMobile && !this.mobileInitialized) {
      this.mobileInitialized = true;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    } else if (!this.isMobile) {
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    const setContainer = (name) => {
      const container = document.createElement('div');
      container.classList.add(name);
      document.body.append(container);
      const rect = container.getBoundingClientRect();
      container.remove();
      document.documentElement.style.setProperty(
        `--${name}-margin`,
        `${rect.left}px`
      );
      document.documentElement.style.setProperty(
        `--${name}-width`,
        `${rect.width}px`
      );
    }
    setContainer('container');
    setContainer('medium-container');
  }
}