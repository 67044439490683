import Util from '../util/util.js';
import FBSDK from '../util/facebook';

export default class ShareButton {
  constructor(el){
    this.$el = el;
    this.isActive = false;
   

    Array.from(this.$el).forEach(item => {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        const url = item.getAttribute('data-url') || location.href;

        console.log(url)

        if(item.getAttribute('data-share-type') == 'facebook'){
          FBSDK.init(() => {            
            this.shareByType(item.getAttribute('data-share-type'), url)
          });
        }else{
          this.shareByType(item.getAttribute('data-share-type'), url);

        }
      });
    });
  }
  shareByType(type, copy){
    let url = copy.match(/(http|https):\/\/[^ ]+/);
    let loc = location.href
    
    if(url && url[0]) url = url[0];
    else url = window.location.href;
    switch (type) {
      case 'copy':
        navigator.clipboard.writeText(copy);
        break;
      case 'twitter':
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURIComponent(`${copy}`)}`,
          'sharer',
          'toolbar=0,status=0,width=580,height=480',
        );
        break;
      case 'facebook':
        
        FB.ui({
          method: 'share',
          //hashtag: hash,
          href: loc,
          quote: copy,
        });
        // window.open(
        //   `https://www.facebook.com/sharer/sharer.php?app_id=123050457758183&u=${url}&quote=el+text`,
        //   'sharer',
        //   'toolbar=0,status=0,width=680,height=580',
        // );
        break;
      case 'whatsapp':
        window.open(
          `https://wa.me/?text=${loc}`,
          '_blank'
        );
        break;
      case 'linkedin':
        window.open(
          `https://linkedin.com/shareArticle?mini=true&url=${loc}&summary=`,
          'sharer',
          'toolbar=0,status=0,width=580,height=325',
        );
        break;
      default:
        break;
    }
  }
}
new ShareButton(document.querySelectorAll('.bt-share-leader'));
