import Util from '../util/util.js';

export default class IconMenu {
  constructor(el){
    this.$el = el;
    this.$el.addEventListener('click', this.toggle.bind(this));
  }
  toggle() {
    this.setState(!this.isActive());
    if (this.onClick) {
      this.onClick({isActive: this.isActive()});
    }
  }
  isActive() {
    return document.documentElement.classList.contains('menu-open');
  }
  setState(state) {
    document.documentElement.classList.toggle('menu-open', state);
    this.$el.classList.toggle('active', state);
  }
}

new IconMenu(document.querySelector('.icon-menu:not([data-no-auto])'))