import Util from '../util/util.js';

export default class MenuPopup {
  constructor(el){
    this.$el = el;
    this.category = 'Atoms';
    this.componentName = 'MenuPopup';
    this.$el.classList.add('no-transition');
    this.$nav = document.querySelector('#navigation');
    this.$bg = this.$el.querySelector('.menu-popup-bg');
    this.$mediasContainer = this.$el.querySelector('.menu-popup-medias-container');
    this.$medias = Array.from(this.$el.querySelectorAll('.menu-popup-media'));
    this.$items = this.$el.querySelectorAll('.menu-popup-item');
    this.$trigger = this.$el.getAttribute('data-trigger') && document.querySelector(this.$el.getAttribute('data-trigger'));
    if (this.$trigger) {
      this.$trigger.addEventListener('click', this.toggle.bind(this));
    }
    this.$el.classList.remove('no-transition');
    this.$items.forEach(($item) => {
      $item.addEventListener('mouseenter', () => {
        this.onHover($item);
      });
    });
    this.$el.querySelector('.menu-popup-items').addEventListener('mouseleave', () => {
      this.$el.removeAttribute('data-bg-color');
      this.$bg.style.backgroundColor = '';
      this.$medias.forEach(($media) => {
        $media.classList.remove('active');
      });
      this.$nav.classList.remove('menu-white');
    });
    Util.waitForLoader(() => {
      console.log(window.pageName)
      
    });
  }
  onHover($item) {
    const color = $item.getAttribute('data-color');
    const name = $item.getAttribute('data-name');

    this.$bg.style.backgroundColor = color;
    this.$el.setAttribute('data-bg-color', color);
    this.$nav.classList.toggle('menu-white', color === '#FAF9F5');
    this.$items.forEach((i) => {
      $item.classList.toggle('active', $item === i);
    });
    this.$medias.forEach(($media) => {
      $media.classList.toggle('active', $media.getAttribute('data-name') === name);
    });
  }
  toggle() {
    this.setState(this.isActive());
    if (this.onClick) {
      this.onClick({isActive: this.isActive()});
    }
  }
  isActive() {
    if(this.$trigger) {
      return this.$trigger.classList.contains('active');
    }
    return document.documentElement.classList.contains('menu-open');
  }
  setState(state) {
    if(this.$trigger) {
      this.$el.classList.toggle('active', state);
      if(!this.isActive()) {
        this.$el.removeAttribute('data-bg-color');
        this.$nav.classList.remove('menu-white');
        this.$el.querySelectorAll('.menu-popup-item').forEach((item, i) => {
          item.classList.remove('active');
        })
      }else {
        let pageName = window.pageName;
        if(pageName === 'index') pageName = 'intro';
        if(pageName === 'leader') pageName = 'leaders';

        this.$el.querySelectorAll('.menu-popup-item').forEach((item, i) => {
          if (item.getAttribute('data-name') === pageName) {
            const event = new Event('mouseenter');
            item.dispatchEvent(event);
          }
        })
      }
    }
  }
}
Array.from(document.querySelectorAll('.menu-popup:not([data-no-auto])')).forEach((el) => {
  new MenuPopup(el);
});